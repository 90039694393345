<!-- eslint-disable vue/no-v-html -->
<template>
  <component
    :is="linkValue ? 'a' : 'div'"
    class="tw-image"
    :class="{ 'tw-live': !isEditorMode }"
    :style="style"
    :target="targetValue"
    :href="linkValue"
  >
    <img
      :style="[
        `--imageH: ${style.height}`,
        `--imageW: ${style.width}`,
        style
      ]"
      :src="imageUrl"
      v-bind="{ ...attr, ...element.attr}"
      role="presentation"
    />
  </component>
</template>

<script lang="ts" setup>
import { type PropType, computed, toRef } from 'vue';
import type { Element as ElementType } from '../../types/model';
import { getAssetFromCdn } from '@shared/utils/helpers';
import { useSpacing } from '@shared/composable/useSpacing';

const props = defineProps({
  tagName: { type: String, default: 'img' },
  value: { type: String, default: '' },
  style: { type: Object, default: () => ({}) },
  attr: { type: Object as PropType<ElementType<'image'>['attr']>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'image'>>, default: () => ({}) },
  isEditorMode: { type: Boolean, default: false },
});

const element = toRef(props, 'element');
const { margin } = useSpacing(element);

const linkValue = computed(() => {return props.element?.attr?.href || props.element?.attr['data-href']})
const targetValue = computed(() => {return props.element?.attr?.target })

function isURL(str: string) {
  try {
    new URL(str);
    return true;
  } catch {
    return false;
  }
}

const imageUrl = computed(() => {
  return isURL(props.value) ? props.value : getAssetFromCdn(props.value)
});
</script>

<style lang="postcss" scoped>
.tw-image {
  /* MARGIN */
  --image-desktop-margin-top: v-bind('margin?.desktop?.top');
  --image-desktop-margin-left: v-bind('margin?.desktop?.left');
  --image-desktop-margin-bottom: v-bind('margin?.desktop?.bottom');
  --image-desktop-margin-right: v-bind('margin?.desktop?.right');

  --image-tablet-margin-top: v-bind('margin?.tablet?.top');
  --image-tablet-margin-left: v-bind('margin?.tablet?.left');
  --image-tablet-margin-bottom: v-bind('margin?.tablet?.bottom');
  --image-tablet-margin-right: v-bind('margin?.tablet?.right');

  --image-mobile-margin-top: v-bind('margin?.mobile?.top');
  --image-mobile-margin-left: v-bind('margin?.mobile?.left');
  --image-mobile-margin-bottom: v-bind('margin?.mobile?.bottom');
  --image-mobile-margin-right: v-bind('margin?.mobile?.right');

  position: relative;
  display: flex;
  justify-content: center;
  height: auto !important;
  max-width: 100%;

  margin-top: var(--image-mobile-margin-top, 0px);
  margin-left: var(--image-mobile-margin-left, 0px);
  margin-bottom: var(--image-mobile-margin-bottom, 0px);
  margin-right: var(--image-mobile-margin-right, 0px);

  &.tw-live {
    img:not([class*='ignore-h-auto']) {
      @media all and (max-width: 769px) {
        height: auto !important;
        width: 100% !important;
        max-width: 600px !important;
      }
    }
  }

  /**
  * If element has this class, containing img will be fixed height as percent,
  * it is necessary trick for equal height columns
  */
  &.has-fixed-height-image img {
    height: 100% !important;
  }
}

.desktop {
  .tw-image {
    margin-top: var(--image-desktop-margin-top, 0px);
    margin-left: var(--image-desktop-margin-left, 0px);
    margin-bottom: var(--image-desktop-margin-bottom, 0px);
    margin-right: var(--image-desktop-margin-right, 0px);
  }
}

.tablet {
  .tw-image {
    margin-top: var(--image-tablet-margin-top, 0px);
    margin-left: var(--image-tablet-margin-left, 0px);
    margin-bottom: var(--image-tablet-margin-bottom, 0px);
    margin-right: var(--image-tablet-margin-right, 0px);
  }
}

.mobile {
  .tw-image {
    margin-top: var(--image-mobile-margin-top, 0px);
    margin-left: var(--image-mobile-margin-left, 0px);
    margin-bottom: var(--image-mobile-margin-bottom, 0px);
    margin-right: var(--image-mobile-margin-right, 0px);
  }
}

.tw-image {
  @media (min-width: 768px) {
    margin-top: var(--image-tablet-margin-top, 0px);
    margin-left: var(--image-tablet-margin-left, 0px);
    margin-bottom: var(--image-tablet-margin-bottom, 0px);
    margin-right: var(--image-tablet-margin-right, 0px);
  }

  @media (min-width: 1024px) {
    margin-top: var(--image-desktop-margin-top, 0px);
    margin-left: var(--image-desktop-margin-left, 0px);
    margin-bottom: var(--image-desktop-margin-bottom, 0px);
    margin-right: var(--image-desktop-margin-right, 0px);
  }
}
</style>
